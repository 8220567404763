/*
* Fonts
*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&subset=cyrillic');

/*
* Third Party
*/
@import "../../bower_components/normalize.css/normalize.css";
@import "../../bower_components/flickity/dist/flickity.css";

/*
* Custom
*/
@import "partials/variables";
@import "partials/app";
@import "partials/product";
@import "partials/card";
@import "partials/element";
@import "partials/header";
@import "partials/footer";
@import "partials/popup";


.center {
    text-align: center;
}
.row__end_xs {
    display: flex;
    justify-content: flex-end;
}
.row__middle_xs {
    display: flex;
    align-items: center;
}