.footer {
    position: relative;
    flex-direction: column;
    flex: 0 0 auto;
    background: #474557;
    // margin: 60px 0 0 0;
}
.footer-logo {
    position: absolute;
    top: -11px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.footer-copyright {
    font-family: Georgia, Helvetica, sans-serif;
    border-top: 1px solid #626170;
    padding: 19px 0 0 4px;
    color: #9497a3;
    font-size: 13px;
    font-style: italic;
    position: relative;
    margin: 112px 18px 20px 18px;
    line-height: 19px;
}
.footer-developer {
    display: flex;
    justify-content: space-between;
    padding: 0 18px 40px;
}
.footer-developer__link {
    color: #b1b6c6;
    text-decoration: none;
    font-size: 12px;
    display: flex;
    align-items: center;
    transition: 0.4s;
}
.footer-developer__link:hover {
    transition: 0.4s;
    color: white;
}
.footer-developer__img {
    width: 38px;
    height: 38px;
    margin-left: 10px;
    background: url('../img/sprite.png') 0 -421px;
    filter: alpha(opacity=50);
    opacity: 0.5;
    transition: 0.4s;
    
}
.footer-developer__link:hover .footer-developer__img {
    opacity: 1;
    transition: 0.4s;
}