.element-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.element-item {
    position: relative;
    width: 284px;
    height: 504px;
    background: url('../img/element-item-frame.png') no-repeat;
    background-position: center bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px; 
}
.element-item__title {
    color: #474557;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: Georgia, Helvetica, sans-serif;
    font-size: 17px;
    line-height: 19px;
    width: 200px;
    height: 46px;
    overflow: hidden;
    display: block;
    margin-bottom: 5px;
}
.element-item__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 235px;
    height: 210px;
    border: 1px solid #a0a9af;
    box-shadow: 2px 2px 0px 0px rgba(160,169,175,1); 
    margin-bottom: 20px;
}
.element-item__subtitle {
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: #666;
    margin: 0 12px;
}
.element-item__description {
    width: 204px;
    line-height: 18px;
    font-size: 12px;
    text-align: center;
    color: #a0a3a8;
    font-style: italic;
}
.element-item__hr {
    width: 237px;
    height: 1px;
    overflow: hidden;
    background: #e60021;
    border: 0px;
    margin-bottom: 15px;
}
