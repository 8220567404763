.card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 60px;
    .card__col {
        margin: 0 10px;
    }
    .card__col:first-child {
        margin-left: 0px;
    }
    .card__col:last-child {
        margin-right: 0px;
    }
    @media screen and (max-width: 1024px) {
        .card__col {
            margin: 0px !important;
        }
    }
    .card__carousel {
        width: 286px;
        height: 235px;
        margin-bottom: 15px;
    }
    .card__carousel-cell {
        width: 280px;
        height: 230px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #a0a9af;
        box-shadow: 2px 2px 0px 0px rgba(160,169,175,1); 
        counter-increment: card__carousel-cell;
    }
    .card__carousel-cell:before {
      display: block;
      text-align: center;
      content: counter(card__carousel-cell);
      color: white;
    }
    .card__carousel_nav {
        height: auto !important;
        .card__carousel-cell {
            width: 91px;
            height: 75px;
            box-shadow: none;
            margin-right: 5px;
            overflow: hidden;
        }
        .card__carousel-cell.is-nav-selected {
            border: 2px solid #e60021;
        }
    }
    .card__title {
        font-size: 19px !important;
    }
    .card__code {
        font-size: 12px;
        color: #fff;
        background: #a0a3a8;
        padding: 2px 5px;
        margin: 8px 0 20px -1px;
        letter-spacing: 1px;
    }
    .card__hr {
        height: 4px;
        width: 30px;
        background: #e60021;
        border: 0;
        overflow: hidden;
        margin: 15px 0 3px 0;
    }
    .card__price {
        text-align: left;
        line-height: 1.6;
    }
    .card__package {
        margin: 15px 0;
        border-top: 1px dashed #ccc;
        border-bottom: 1px dashed #ccc;
    }
    .card__related {
        width: 100%;
        margin-top: 40px;
    }
    .card__description {
        border: 3px solid #474557;
        margin-top: 30px;
        padding: 30px 20px;
        line-height: 18px;
        font-size: 12px;
        .card__title-descr {
            font-size: 11px;
            letter-spacing: 0.825px;
            text-transform: uppercase;
            text-align: center;
            margin: 0 0 20px 0;
        }
        iframe {
            width: 100%;
        }
    }
}
.package {
    display: flex;
    flex-direction: row;
    .package__col {
        width: 100%;
        .package__title {
            font-size: 11px;
            color: #a0a3a8;
            font-family: Georgia, Helvetica, sans-serif;
            text-align: center;
            letter-spacing: 0.55px;
            text-transform: uppercase;
        }
        .package__value {
            font-size: 11px;
            color: #474557;
            font-family: Georgia, Helvetica, sans-serif;
            text-align: center;
            font-style: italic;
        }
    }
}
.related {
    position: relative;
    padding: 39px;
    border: 1px solid #ced3d9;
    .related__toggle {
        position: absolute;
        top: -11px;
        right: 10px;
        width: 27px;
        height: 22px;
        border-top: 0;
        border-bottom: 0;
        border-left: 4px solid white;
        border-right: 4px solid white;
        background: url('../img/sprite-btn.png') right center no-repeat;
        cursor: pointer;
    }
    .related__toggle_open {
        background: url('../img/sprite-btn.png') left center no-repeat;
    }
    .related__title {
        position: absolute;
        top: -10px;
        left: 5px;
        padding: 0 5px;
        font-family: Georgia,Helvetica,sans-serif;
        background: #fff;
        font-size: 15px;
        text-transform: uppercase;
    }
    .related__count {
        font-size: 15px;
        font-family: Georgia, Helvetica, sans-serif;
        font-weight: bold;
        line-height: 19px;
        color: #666;
        text-align: center;
    }
    .related__products {
        display: flex;
        flex-direction: column;
        display: none;
        .related-product {
            position: relative;
            width: 204px;
            height: 283px;
            background: url('../img/related-products-frame.png') no-repeat;
            background-position: center 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px;
            .related-product__title {
                color: #474557;
                text-decoration: none;
                text-align: center;
                font-family: Georgia, Helvetica, sans-serif;
                font-size: 13px;
                width: 147px;
                height: 55px;
                overflow: hidden;
                display: block;
                margin-bottom: 10px;
            } 
            .related-product__image {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #a0a9af;
                box-shadow: 2px 2px 0px 0px rgba(160,169,175,1); 
                margin-bottom: 13px;
            }
            .related-product__price {
                font-size: 15px;
            }
            .related-product__hr {
                width: 112px;
                height: 1px;
                background: #e60021;
                border: 0;
            }
            &:last-child {
                margin-bottom: 20px;
            }
        }
    }
}