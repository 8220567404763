.popup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    display: none;
    .popup__content {
        width: 100%;
        max-width: 640px;
        background: white;
        margin: 0 auto;
        padding: 50px;
        color: #666;
        line-height: 1.5;
        position: relative;
        .popup__close {
            display: block;
            width: 27px;
            height: 28px;
            position: absolute;
            right: 12px;
            top: -13px;
            background: url('../img/sprite.png') -78px -861px;
            text-indent: -9999px;
            border: 0;
        }
        .popup__close:hover {
            background: url('../img/sprite.png') -106px -861px;
        }
    }
}
.popup_visible {
    display: flex;
}