.product-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 50px;
}
.product-items__wrap {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 50px;
}
.product-items__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 39px;
    background: url('../img/more.png') no-repeat;
    color: white;
    border: 0px;
    font-size: 11px;
    text-transform: uppercase;
    // box-shadow: 1px 0px 8px 0px rgba(0,0,0,0.25);
    cursor: pointer;
}
.product {
    position: relative;
    width: 284px;
    height: 465px;
    background: url('../img/product-item-frame.png') no-repeat;
    background-position: center bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px; 
}
.product__title {
    color: #474557;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: Georgia, Helvetica, sans-serif;
    font-size: 15px;
    width: 204px;
    height: 70px;
    overflow: hidden;
    display: block;
    margin-bottom: 5px;
}
.product__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 235px;
    height: 210px;
    border: 1px solid #a0a9af;
    box-shadow: 2px 2px 0px 0px rgba(160,169,175,1); 
    margin-bottom: 20px;
}
.product__price {
    text-align: center;
}
.product__description {
    width: 204px;
    line-height: 18px;
    font-size: 12px;
    text-align: center;
    color: #666;
}
.product__hr {
    width: 156px;
    height: 1px;
    overflow: hidden;
    background: #e60021;
    border: 0px;
}


.about {
    font-family: Georgia, Helvetica, sans-serif;
    font-weight: normal;
    font-style: italic;
    text-align: center;
}
.about__h1 {
    font-style: normal;
}
.about__hr {
    width: 57px;
    height: 4px;
    margin-top: 30px;
    border: 0;
    background-color: #e60021;
}
.about__sub {
    position: relative;
    margin: 30px 0 0 -18px;
}
.about__img {
    width: 200px;
    height: 308px;
    background: url('../img/about-img.png') no-repeat;
    background-position-y: 5px;
    background-size: 100%;
}
.about__text {
    position: absolute;
    top: 0;
    left: 170px;
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
    text-transform: uppercase;
    text-align: left;
}