@font-face {
    font-family: 'georgia';
    src: url('../fonts/georgia.eot');
    src: url('../fonts/georgia.eot') format('embedded-opentype'),
         url('../fonts/georgia.woff2') format('woff2'),
         url('../fonts/georgia.woff') format('woff'),
         url('../fonts/georgia.ttf') format('truetype'),
         url('../fonts/georgia.svg#georgia') format('svg');
}

* {
    box-sizing: border-box;
    &:active, &:hover, &:focus {
        outline: 0;
    }
}

html {
    height: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    width: 100%;
    min-width: 320px;
    height: 100%;
    background-color: $body-color-bg;
    color: $body-color-text;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: $body-color-link;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    .main {
        flex: 1 0 auto;
        margin: 0 18px;
    }
    .main h1 {
        font-family: Georgia, Helvetica, sans-serif;
        font-weight: normal;
        font-size: 21px;
        text-transform: uppercase;
        line-height: 24px;
        margin: 40px 0 30px 0;
    }
    .main h4 {
        text-transform: uppercase;
        font-size: 13px;
        color: #474557;
        font-weight: normal;
    }
}

.content {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 60px;
}
.content p {
    margin: 19px 0;
}
.content a {
    color: $body-color-link;
}
.content dl dt {
    margin-bottom: 12px;
    line-height: 17px;
    color: #a0a3a8;
}
.content dl dd {
    margin: 0 0 28px 0;
    padding: 0px;
    font-weight: bold;
    color: #474557;
}
.content dl dd a {
    font-weight: normal;
}
.content--contact {
    font-size: 13px;
}
.content--contact h3 {
    font-family: Georgia, Helvetica, sans-serif;
    font-size: 15px;
    line-height: 19px;
    text-transform: uppercase;
}
.map {
    margin: 30px -18px;
    height: 215px;
    background-color: #f4f4f4;
}
.content blockquote {
    border-top: 1px dashed #cfd1d3;
    border-bottom: 1px dashed #cfd1d3;
    background: #f2fcff;
    font-family: Georgia, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 13px;
    color: #666;
    line-height: 16px;
    padding: 20px 18px;
    margin: 20px -18px !important;
    
}
.blockquote--primary {
    padding: 20px 44px !important;
    background-image: url('../img/icon-attention.png') !important;
    background-repeat: no-repeat !important;
}
.about {
    margin-bottom: 0px;
}
.print-link {
    display: block;
    background: url('../img/sprite.png') 0 -460px;
    background-repeat: no-repeat;
    margin: 12px 0;
    padding: 0 0 0 34px;
    line-height: 24px;
    font-size: 13px;
}

// Component Site Links
.site-links {
    display: flex;
    align-items: center;
}
.site-links__link {
    overflow: hidden;
    border: none;
    text-indent: -9999px;
    margin: 0 10px;
}
.site-links__separator {
    background: none #e0e6e9;
    height: 21px;
    width: 1px;
    overflow: hidden;
}
.site-links__link:first-child {
    margin-left: 0px;
}
.site-links__link:last-child {
    margin-right: 0px;
}
.site-links__sitemap {
    background: url('../img/sprite.png');
    background-position: 0 0;
    width: 14px;
    height: 12px;
}
.site-links__sitemap:hover {
    background-position: -19px 0;
}
.site-links__message {
    background: url('../img/sprite.png');
    background-position: 0 -15px;
    width: 17px;
    height: 12px;
}
.site-links__message:hover {
    background-position: -19px -15px;
}
// Component Site Links

.price {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    color: #666;
    .price__title {
        font-weight: normal;
        font-size: 14px;
        color: #666;
        line-height: 18px;
        border-bottom: 1px solid #e60021;
        padding: 2px 0;
    }
    .price__suffix {
        font-size: 13px;
        font-style: italic;
        font-family: Georgia, Helvetica, sans-serif;
        font-weight: normal;
        padding: 0 0 0 10px;
    }
}
.price--special {
    font-size: 28px !important;
    color: #464357;
    .price__suffix {
        color: #666;
        font-size: 15px;
    }
}
.visible {
    display: block !important;
    opacity: 1;
}
.scroll-locked {
    overflow: hidden;
}