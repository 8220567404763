.header {
    margin-bottom: 40px;
}
.header-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    background: none #f2fcff;
    border-bottom: 2px solid #cacccf;
    padding: 0 18px;
}
.header-toolbar__lang {
    color: #9c9fa7;
    text-decoration: none;
    font-size: .9em;
}
.header-phone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 18px;
    height: 43px;
    border-bottom: 1px solid #cbd0d7;
}
.header-phone__link {
    font-size: 16px;
}
.header-phone__callback span {
    position: relative;
    top: -1px;
    color: #e60021;
    font-size: .8em;
}
.header-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 188px;
}
.header-logo__img {
    width: 234px;
    height: 89px;
}
.header-logo__info {
    display: block;
    width: 234px;
    color: #7b7d83;
    font-style: italic;
    font-size: .9em;
    text-align: center;
    margin: 8px auto 0 auto;
    font-family: Georgia, Helvetica, sans-serif;
}
.nav {
    position: relative;
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
}
.nav::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #dfe0e2;
}
.nav__row {
    display: flex;
    justify-content: center;
    width: 100%;
}
.nav__button {
    position: relative;
    z-index: 2;
    width: 209px;
    height: 50px;
    background-color: #474557;
    color: white;
    border: 0px;
    font-size: 11px;
    text-transform: uppercase;
    // box-shadow: 1px 0px 8px 0px rgba(0,0,0,0.25);
    cursor: pointer;
    background: url('../img/nav.png') no-repeat;
}
.nav__list {
    display: none;
    position: absolute;
    top: 13px;
    list-style: none;
    width: 100%;
    background: white;
    z-index: 1;
    box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.08); 
    border-top: 1px solid #e4e3e6;
    padding: 0;
    padding-top: 25px;
}
.nav__list_visible {
    display: inline;
}
.nav__list_sub {
    top: -1px;
}
.nav__item {
    height: 47px;
    border-bottom: 1px solid #e4e3e6;
    background: white;
    text-transform: uppercase;
    font-size: 11px;
    display: flex;
    align-items: center;
}
.nav__item:last-child {
    border: 0px;
}
.nav__item_close {
    position: relative;
    color: #e60021 !important;
}
.nav__item_close::after {
    content: '-';
    font-size: 22px;
    position: absolute;
    top: 8px;
    right: 18px;
}
.nav__item_close span {
    color: #e60021 !important;
}
.nav__link {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #474557;
    padding-left: 18px;
}
.callback {
    display: none;
}
.callback_visible {
    display: inline;
}
.callback {
    position: absolute;
    margin-top: 20px;
    left: 18px;
    right: 18px;
    // margin: 20px 18px 0 18px;
    padding: 10px;
    border: 1px solid #ced3d9;
    background: white;
    z-index: 10;
}
.callback-form__title {
    position: relative;
    top: -19px;
    padding: 0 5px;
    font-family: Georgia, Helvetica, sans-serif;
    background: white;
    font-size: 15px;
    text-transform: uppercase;
}
.callback-form__field {
    margin-bottom: 20px;
}
.callback-form__field img {
    margin-bottom: 10px;
}
.callback-form__label {
    display: block;
    margin-bottom: 10px;
    color: black;
}
.inputtext,
.callback-form__input {
    width: 100%;
    height: 28px;
    padding: 0 10px;
    border: 1px solid #c2c7c8;
    border-radius: 3px;
    background-color: #f5fdff;
}
.callback__close {
    display: block;
    width: 27px;
    height: 28px;
    position: absolute;
    right: 12px;
    top: -14px;
    background: url('../img/sprite.png') -78px -861px;
    padding: 0 5px;
    text-indent: -9999px;
}
.callback__close:hover {
    background: url('../img/sprite.png') -106px -861px;
}
.callback-form__button {
    width: 150px;
    height: 42px;
    line-height: 42px;
    color: #474557;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    background: url('../img/sprite.png') -225px -894px;
    font-weight: bold;
    border: 0;
    margin: 30px auto;
    display: block;
}